<template>
  <b-nav-item-dropdown
    v-if="showProducts"
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="'outline-primary'"
        size="sm"
      >
        <feather-icon
          icon="MoreVerticalIcon"
          size="10"
        />
        <span> {{ currentLocale.name }}</span>
      </b-button>
    </template>
    <b-dropdown-item
      v-for="localeObj in produtos"
      :key="localeObj.key"
      @click="trocarContexto(localeObj.key)"
    >
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import { getContext, setContext, isHaveProduct } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BButton,
  },

  directives: {
    Ripple,
  },

  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.produtos.find(l => l.key === getContext())
    },

    showProducts() {
      return isHaveProduct('futures') && isHaveProduct('spot')
    },

  },

  methods: {
    trocarContexto(key) {
      setContext(key)
      window.location.href = `/${key}`
    },
  },

  setup() {
    /* eslint-disable global-require */
    const produtos = [
      {
        key: 'spot',
        name: 'Spot',
      },
      {
        key: 'futures',
        name: 'Futures',
      },
    ]
    /* eslint-disable global-require */

    return {
      produtos,
    }
  },
}
</script>

<style>

</style>
