<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >

      <template v-for="(item, index) in alertList">
        <b-alert
          :key="index"
          :variant="item.estilo"
          show
          dismissible
        >
          <div class="alert-body">
            <span><strong>{{ item.titulo }}</strong> <div v-html="item.mensagem" /></span>
          </div>
        </b-alert>
      </template>

      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BAlert } from 'bootstrap-vue'
import { getContext } from '@/auth/utils'
import store from '@/store'

export default {
  components: {
    AppBreadcrumb,
    BAlert,
  },

  computed: {
    alertList() {
      const alertList = []
      this.$store.state.alerta.alertas.forEach(item => {
        // if (item.produto.toLowerCase() === getContext()) {
        alertList.push(item)
        // }
      })
      return alertList
    },
  },

  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    const contexto = getContext()
    store.dispatch('alerta/getAlerta', contexto)
      .then(() => {})

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>
