<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="sinoContador"
        class="text-body"
        icon="BellIcon"
        size="21"
        badge-classes="bg-danger"
        @click.prevent="setLidos"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Notificações') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ data.naoLida == '' ? '0' : data.naoLida }} {{ $t('Novas') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="data.notificacoes && data.notificacoes.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <template v-for="(notification, index) in data.notificacoes">
        <b-link :key="index">
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :variant="getEstiloNotificacao(notification.estilo)"
              >
                <feather-icon :icon="getIconNotificacao(notification.estilo)" />
              </b-avatar>
            </template>
            <p class="media-heading d-flex">
              <span class="font-weight-bolder">
                {{ notification.titulo }}
              </span>
              <small>{{ elapsedTime(notification.dataCriacao) }}</small>
            </p>
            <small class="notification-text">{{ notification.mensagem }}</small>
          </b-media>

        </b-link>
      </template>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="data.notificacoes && data.notificacoes.length"
      class="dropdown-menu-footer"
    ><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      :to="{path:'/notificacao'}"
    >{{ $t('lerTodasNotificacoesBtn') }}</b-button>
    </li>

    <p
      v-if="!data.notificacoes || !data.notificacoes.length"
      class="m-0 p-1 text-center"
    >
      {{ $t('nenhumaNotificacao') }}
    </p>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import utilsMixin from '@/mixins/utilsMixin'

export default {

  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      data: {
        notificacoes: [],
        naoLida: 0,
      },
      polling: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },

  computed: {
    sinoContador() {
      if (this.data.naoLida > 99) {
        return '99+'
      }
      return this.data.naoLida
    },
  },

  created() {
    this.pollData()
  },

  methods: {

    getNotificacoes() {
      const self = this
      this.$store.dispatch('notificacao/getNotificacoesSino')
        .then(response => {
          self.data = response.data
        })
    },

    setLidos() {
      this.$store.dispatch('notificacao/setLidos')
        .then(() => {})
    },

    pollData() {
      this.getNotificacoes()
      const self = this
      this.polling = setInterval(() => {
        self.getNotificacoes()
      }, 5000)
    },

    /* eslint no-else-return: "error" */
    getIconNotificacao(estilo) {
      if (estilo === 'success') {
        return 'CheckIcon'
      } else if (estilo === 'danger') {
        return 'XIcon'
      } else if (estilo === 'warning') {
        return 'AlertTriangleIcon'
      }
      return 'MessageCircleIcon'
    },

    getEstiloNotificacao(estilo) {
      if (estilo === 'success') {
        return 'light-success'
      } else if (estilo === 'danger') {
        return 'light-danger'
      } else if (estilo === 'warning') {
        return 'light-warning'
      }
      return 'light-info'
    },

  },
}
</script>

<style>

</style>
