<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'
import { onUnmounted, computed, watch } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import configuracaoContaStoreModule from '@/views/conta/configuracao/configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

export default {
  components: {
    BNavItem,
  },

  setup() {
    // Register module
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
    })

    const { skin } = useAppConfig()

    const setConfigTela = () => {
      store.dispatch('configuracao-conta/setConfigTela', { modoTela: skin.value })
    }

    const isDark = computed(() => skin.value === 'dark')

    watch(isDark, () => {
      // eslint-disable-next-line no-use-before-define
      setConfigTela()
    })

    return { skin, isDark }
  },

}
</script>
