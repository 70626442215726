<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        v-if="currentLocale"
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <!--span class="ml-50 text-body">{{ currentLocale.name }}</span-->
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store'
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import configuracaoContaStoreModule from '@/views/conta/configuracao/configuracaoContaStoreModule'

const CONFIGURACAO_CONTA_STORE_MODULE_NAME = 'configuracao-conta'

// Carregue as imagens fora do escopo do require
const brFlag = require('@/assets/images/flags/pt-br.png')
const enFlag = require('@/assets/images/flags/en.png')
const esFlag = require('@/assets/images/flags/es.png')

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales: [
        {
          locale: 'pt-br',
          img: brFlag,
          name: 'Brasil',
        },
        {
          locale: 'en',
          img: enFlag,
          name: 'English',
        },
        {
          locale: 'es',
          img: esFlag,
          name: 'Espanha',
        },
      ],
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },

  created() {
    if (!store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.registerModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME, configuracaoContaStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)) store.unregisterModule(CONFIGURACAO_CONTA_STORE_MODULE_NAME)
  },

  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale
      store.dispatch('configuracao-conta/setLanguage', { idioma: locale })
        .then(() => {
          const dataUser = JSON.parse(localStorage.getItem('userData'))
          dataUser.idioma = locale
          localStorage.setItem('userData', JSON.stringify(dataUser))
        })
    },
  },
}

</script>

<style>

</style>
