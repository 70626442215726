<template>
  <div>
    <b-overlay
      :show="loadingOverlayTermo"
      rounded="sm"
    >
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT  © {{ new Date().getFullYear() }}
          <span class="d-none d-sm-inline-block">, All rights Reserved</span>
        </span>
        <span class="float-md-right d-none d-md-block">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-secondary"
            @click="openModalTermo"
          >
            Termos & Políticas
          </b-button>
        </span>
      </p>
    </b-overlay>

    <b-modal
      id="modal-termo-footer"
      ref="modal-termo-footer"
      centered
      title="Termos"
      ok-only
      ok-title="Fechar"
    >
      <b-card-text v-html="content" />
    </b-modal>
  </div>

</template>

<script>
/* eslint-disable */
import {
  BModal, BButton, VBModal, BCardText, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BOverlay,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      content: '',
      loadingOverlayTermo: false,
    }
  },

  methods: {

    openModalTermo() {
      if (this.content === '') {
        this.getTermoUso()
      }
      this.$refs["modal-termo-footer"].show()
    },

    getTermoUso() {
      this.loadingOverlayTermo = true
      axios.get('/publico/empresa/termo', { params: { dominio: window.location.host } })
        .then(response => {
          this.content = response.data
        }).finally(() => {
          this.loadingOverlayTermo = false
        });
		},
  }

}
</script>
